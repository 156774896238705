import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


const MySwal = withReactContent(Swal)

const success = (message) => {
  return MySwal.fire({
    // title: message,
    icon: 'success',
    html: '<p>' + message + '</p>'
  });
};

const error = (message='Something went wrong ! Please try again later.') => {
  return MySwal.fire({
    // title: message,
    icon: 'error',
    html: '<p>' + message + '</p>'  ,
  });
};


const Alerts = {
    success: success,
    error: error,
  }
  
  export default Alerts;