import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import "./style.css";
import CheckoutDetails from "./checkoutPages/checkoutDetail";
import StripeCheckout from "./checkoutPages/stripeCheckout";
import RazorPayment from "./checkoutPages/razorpayCheckout";

const AdmissionFeeAndFinancing = ({ state, country, course ,pageSlug, programOverview}) => {
  const [isDetails, setIsDetails] = useState(null);
  return (
    <Box>
      <Container>
        {country?.country_code && isDetails === null ? (
          <CheckoutDetails
            state={state}
            country={country}
            setIsDetails={setIsDetails}
            isDetails={isDetails}
          />
        ) : (
          country?.country_code === "IN" ? <RazorPayment
            state={state}
            country={country}
            setIsDetails={setIsDetails}
            isDetails={isDetails}
            course={course}
          pageSlug={pageSlug}
          programOverview={programOverview}
          /> : <StripeCheckout
            state={state}
            country={country}
            setIsDetails={setIsDetails}
            isDetails={isDetails}
            course={course}
            pageSlug={pageSlug}
          programOverview={programOverview}
          />
        )}
      </Container>
    </Box>
  );
};

export default AdmissionFeeAndFinancing;
