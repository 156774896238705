import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../globalPages/style.css";

const FailedPage = () => {
  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 4, md: 12 }}
        sx={{
          display: "flex",
        }}
      >
        <Grid item xs={2} sm={4} md={12}>
          <Box
            className="thankYouBG"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Image src={logo} alt="logo" /> */}
            <Typography
              sx={{
                fontSize: "96px",
                fontWeight: 600,
              }}
            >
              Payment Failed{" "}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              Payment Failed . Please verify your payment details and try again.
              If the issue persist, contact us at support@qwikskills.com for
              assistance.
            </Box>
            <a href="https://qwikskills.com/">
              <button
                // onClick={handleDownloadBrochure}
                style={{
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                className="landingPrimaryBTN"
                variant="contained"
              >
                Home
              </button>
            </a>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FailedPage;
