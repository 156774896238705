import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import "./style.css";
import CheckoutDetails from "./checkoutPages/checkoutDetail";
import RazorPayment from "./checkoutPages/razorpayCheckout";

const AdmissionFeeAndFinancing = ({ state, country, internshipId,internshipPriceId, packageDetail}) => {
  const [isDetails, setIsDetails] = useState(null);
  return (
    <Box>
      <Container>
        {country?.country_code && isDetails === null ? (
          <CheckoutDetails
            state={state}
            country={country}
            setIsDetails={setIsDetails}
            isDetails={isDetails}
          />
        ) : (
          <RazorPayment
            state={state}
            country={country}
            setIsDetails={setIsDetails}
            isDetails={isDetails}
            internshipId={internshipId}
            packageDetail={packageDetail}
            internshipPriceId={internshipPriceId}
          /> 
        )}
      </Container>
    </Box>
  );
};

export default AdmissionFeeAndFinancing;
