import React from 'react';
import { BrowserRouter as AppRoute, Routes, Route } from 'react-router-dom';
import ThankYou from '../components/globalPages/thankyouPage';
import FailedPage from '../components/globalPages/FailedPage';
import PaymentPage from '../pages/paymentPage';
import { NotFound } from '../components/globalPages/notfound';
import PackagePage from '../pages/packagePage';
import EbookPaymentPage from '../pages/EbookPaymentPage';
import InternshipPaymentPage from '../pages/IntershipPayment';

const PageRoutes = () => {
  return (
    <AppRoute>
      <Routes>
        <Route path="training/:uuid" element={<PaymentPage />} />
        <Route path="package/:uuid" element={<PackagePage />} />
        <Route path="ebook/:uuid" element={<EbookPaymentPage />} />
        <Route path="internship/:id/:priceId" element={<InternshipPaymentPage />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/failed" element={<FailedPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppRoute>
  )
}

export default PageRoutes