import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormFeedback, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import "../style.css";
import Razorpay from "../../../images/razorpay.svg";
import Stripe from "../../../images/stripe.png";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "./CardSectionStyles.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getDecryptedData } from "../../../Utility/utils";
import Alerts from "../../../components/globalPages/Alerts";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const StripePayment = ({ country, course , pageSlug}) => {

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState('');

  const stripe = useStripe();
  const elements = useElements();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const userDetails = localStorage.getItem("userData");
    const getDecrypted=getDecryptedData(JSON.parse(userDetails));
    
    setUser(getDecrypted);
  }, [country])

  const onSubmitHandler = (data) => {
    setIsSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const requestBody = {
      "email": user?.email,
      // "courseId": 1,
      "courseId": course?.course_id,
      "paymentGateway": "STRIPE",
      // "amount": "34",
      "amount": course?.course_price?.USD?.discountedPrice,
      "firstName": user?.firstName,
      "lastName": user?.lastName,
      "phoneNumber": user?.phone,
      "stateName": user?.state,
      "gstNumber": user?.gst_number || "",
      "companyName": user?.company_name || "",
    }
    course?.course_id && course?.course_price?.USD?.discountedPrice && axios.post(`${process.env.REACT_APP_API_URL}/payments/live-course`, requestBody).then(async (res) => {

      const result = await stripe.confirmCardPayment(res?.data?.data?.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement), // here cardElement is card detail data json (card holder, card number , card cvc, and cards expiry)
            billing_details: {
              name: user?.firstName + user?.lastName,
            },
          },
        }
      );

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        setIsSubmitting(false);
        navigate('/failed');
      } else {
        if (result.paymentIntent.status === "succeeded") {
          setIsSubmitting(false);
          navigate('/thankyou')
        }
      }
    }).catch((err) => {
      setIsSubmitting(false);
      Alerts.error(err?.response?.data?.message);
      setInterval(() => {
          window.location.assign(`https://qwikskills.com/${pageSlug?.category}/${pageSlug?.slug}`);
        }, 3000);
    })


  };
  return (
    <Box>
      <Box sx={{ textAlign: "center", mb: 3 }}>
        {country && country?.country_code === "IN" ? (
          <img src={Razorpay} alt="" />
        ) : (
          <img src={Stripe} alt="" height={"50px"} />
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmitHandler)} className="payment-form">
        <Container>
          <Grid
            container
            xs={12}
            sx={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "10px",
              gap: 3,
              padding: "60px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: 'column', lg: 'row' } }}
            >
              <Grid xs={12} lg={7}>
                <FormGroup>
                  <Label
                    required
                    style={{
                      marginBottom: ".5rem",
                      fontWeight: "600",
                      opacity: ".6",
                    }}
                  >
                    Email<span style={{ color: "red" }}>*</span>{" "}
                  </Label>
                  <TextField
                    size={"small"}
                    value={user && user?.email}
                    disabled
                  />
                  {errors.email && (
                    <FormFeedback style={{ color: "red", fontSize: "12px" }}>
                      {errors?.email?.message}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormGroup>
                  <Label
                    required
                    style={{
                      marginBottom: ".5rem",
                      fontWeight: "600",
                      opacity: ".6",
                    }}
                  >
                    Pay Amount<span style={{ color: "red" }}>*</span>{" "}
                  </Label>
                  <TextField size={"small"} value={ course?.course_price &&  course?.course_price?.USD?.discountedPrice} disabled />
                  {errors.amount && (
                    <FormFeedback style={{ color: "red", fontSize: "12px" }}>
                      {errors?.amount?.message}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Grid>
            </Grid>

            <CardElement options={CARD_ELEMENT_OPTIONS} />

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#8341ea",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#8341ea",
                  },
                }}
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                disabled={!stripe || isSubmitting}
              >
                Complete Payment{ isSubmitting &&  <CircularProgress color="inherit" sx={{marginLeft:"1rem"}} size={"1.5rem"}  disableShrink /> }
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </Box>
  );
};

export default StripePayment;
