import { Box, Container, Grid } from "@mui/material";
import React from "react";
import "./style.css";
import Vector from "../../images/ebookpy.jpg";

const ProgramOverview = () => {

  return (
    <Box>
      <Container>
        <Grid
          container
          xs={12}
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 5,
            mt:4,
            fontSize: "1.125rem",
          }}
        >

        <img src={Vector} alt="vector" width={"600px"} />
        </Grid>
      </Container>
    </Box>
  );
};

export default ProgramOverview;
