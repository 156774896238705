import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import ProgramOverview from "./paymentOverview";
import AdmissionFeeAndFinancing from "./admissionFee";
import axios from "axios";
import { State } from "country-state-city";
import Loader from '../../components/Loader/loader' ;
import { useParams } from "react-router-dom";
import NoData from '../../images/broken_training.jpg'

const EbookPaymentPage = () => {
  const { uuid } = useParams();

  const [country, setCountry] = useState({
    country_name: "",
    country_code: "",
  });
  const [ipAddress, setIpAddress] = useState(""); //eslint-disable-line
  const [state, setState] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(true);//eslint-disable-line
  const [ebookId, setEbookId]=useState('');


  useEffect(() => {

    if (!uuid) {
      return;
    }
    setEbookId(uuid);

  }, []); //eslint-disable-line



  useEffect(() => {
    const fetchIpCountry = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;
        setIpAddress(ipAddress);
        localStorage.setItem("ipAddress", ipAddress);

        // Fetch the country information
        const countryResponse = await axios.get(
          `https://ipapi.co/${ipAddress}/json/`
        );
        
        const countryName = countryResponse?.data?.country_name;
        const countryCode = countryResponse?.data?.country_code;
        setCountry({
          country_name: countryName,
          country_code: countryCode,
        });
        localStorage.setItem("country",countryCode);
      } catch (error) {
        console.error("Error fetching IP and country:", error);
      }
    };
    fetchIpCountry();
  }, [uuid]); // eslint-disable-line



  useEffect(() => {
    if (country?.country_code) {
      const state = State?.getStatesOfCountry(country.country_code);
      setState(state);
    }
  }, [country]);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // pt: 3,
          height:"fit-content"
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "1700px" }}>
          {country?.country_code && state ? (
            isDataAvailable === true ? <Grid container xs={12} pt={3}>
              <Grid item xs={12} lg={6} sm={12}>
                <ProgramOverview
                  country={country}
                />
              </Grid>
              <Grid item xs={12} lg={6} sm={12}>
                <AdmissionFeeAndFinancing
                  state={state}
                  country={country}
                  ebookId={ebookId}
                />
              </Grid>
            </Grid> : <Grid container xs={12} sx={{display:'flex',justifyContent:"center",alignItems:"center"}}>
             <div><img style={{width:"100vw", height:"100vh"}} src={NoData} alt="No training found" />
             <div className="notFound">
             <a href="https://qwikskills.com/"><button className="backbutton">Back to Home</button></a> 
             </div>
             </div>
            </Grid>
          ) : (
            <Box sx={{ height: "90vh" }}><Loader /></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EbookPaymentPage;
