import './App.css';
import Route from './routes/route';

function App() {
  return (
    <Route />
  );
}

export default App;
