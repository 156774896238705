import {
  Box,
  Button,
  Checkbox,
  Container,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import '../style.css';
import { FormFeedback, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { encryptData32 } from '../../../Utility/utils';

const gstRegex =
  /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .matches(emailRegex, 'Enter valid email address')
    .required('Required'),
  phone: yup.number().required(),
  firstName: yup
    .string()
    .required()
    .matches(/^[A-Za-z ]*$/)
    .min(3),
  lastName: yup
    .string()
    .required()
    .matches(/^[A-Za-z ]*$/),
  state: yup.string().required(),
  gst_number: yup
    .string()
    .matches(gstRegex, 'Invalid GST number')
    .notRequired(),
  company_name: yup.string(),
});

const CheckoutDetails = ({ state, country, setIsDetails }) => {
  const [isGstin, setIsGstin] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    if (!isGstin) {
      delete data['gst_number'];
      delete data['company_name'];
    }
    setIsDetails(data);
    localStorage.setItem('userData', JSON.stringify(encryptData32(data)));
    reset();
    setValue('state', '');
  };

  const handleFunction = () => {
    setIsGstin(!isGstin);
    setValue('gst_number', '');
    setValue('company_name', '');
  };

  const handleTermsNpolicy = () => {
    setIsAccepted(!isAccepted);
  };

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 22, md: 36 },
            fontWeight: 700,
            mb: 3,
            textAlign: 'center',
          }}
          variant='h2'
        >
          Secure Your <span className={'hightLightText'}>Spot </span> !
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className='checkoutdetails-form'
      >
        <Container>
          <Grid
            container
            xs={12}
            sx={{
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              borderRadius: '10px',
              gap: 3,
              padding: '60px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid xs={12} lg={5}>
                <FormGroup>
                  <Label required style={{ marginBottom: '.5rem' }}>
                    First Name<span style={{ color: 'red' }}>*</span>{' '}
                  </Label>
                  <TextField
                    size={'small'}
                    {...register('firstName')}
                    placeholder='Enter First Name'
                  />
                  {errors.firstName && (
                    <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                      Please Enter First Name
                    </FormFeedback>
                  )}
                </FormGroup>
              </Grid>
              <Grid xs={12} lg={6}>
                <FormGroup>
                  <Label required style={{ marginBottom: '.5rem' }}>
                    Last Name<span style={{ color: 'red' }}>*</span>{' '}
                  </Label>
                  <TextField
                    size={'small'}
                    {...register('lastName')}
                    placeholder='Enter Last Name'
                  />
                  {errors.lastName && (
                    <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                      Please Enter Last Name
                    </FormFeedback>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Label required style={{ marginBottom: '.5rem' }}>
                  Email ID<span style={{ color: 'red' }}>*</span>{' '}
                </Label>
                <TextField
                  size={'small'}
                  type='email'
                  {...register('email')}
                  placeholder='Enter Email'
                />
                {errors.email && (
                  <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                    {errors?.email?.message}
                  </FormFeedback>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Label required style={{ marginBottom: '.5rem' }}>
                  Phone Number<span style={{ color: 'red' }}>*</span>{' '}
                </Label>
                <Box>
                  <PhoneInput
                    size={'small'}
                    specialLabel={''}
                    onChange={(e) => setValue('phone', e)}
                    country={country?.country_code === 'IN' ? 'in' : 'us'}
                    inputStyle={{
                      borderColor: '#afafaf',
                      width: '100%',
                    }}
                    name='phone'
                    id={'phone'}
                  />
                  {errors.phone && (
                    <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                      Please Enter Phone Number
                    </FormFeedback>
                  )}
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Label required style={{ marginBottom: '.5rem' }}>
                  Select State<span style={{ color: 'red' }}>*</span>{' '}
                </Label>
                <Select
                  size={'small'}
                  {...register('state')}
                  getOptionLabel={(options) => {
                    return options['name'];
                  }}
                  getOptionValue={(options) => {
                    return options['name'];
                  }}
                  onChange={(val) => {
                    setValue('state', val?.name);
                  }}
                  options={state ? state : []}
                />
                {errors.state && (
                  <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                    Please Enter Your State
                  </FormFeedback>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sx={{ fontSize: '14px' }}>
              <Checkbox
                sx={{ ml: [-1] }}
                value={isGstin}
                size='small'
                onChange={() => handleFunction()}
              />
              {'  '}
              <span>Do you have GSTIN?</span>
            </Grid>
            {isGstin && (
              <>
                <Grid item xs={12}>
                  <FormGroup>
                    <Label required style={{ marginBottom: '.5rem' }}>
                      Company Name{' '}
                    </Label>
                    <TextField
                      size={'small'}
                      {...register('company_name')}
                      placeholder='Enter Company Name'
                    />
                    {errors.company_name && (
                      <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                        Please Enter Company Name
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <Label required style={{ marginBottom: '.5rem' }}>
                      GST Number{' '}
                    </Label>
                    <TextField
                      size={'small'}
                      {...register('gst_number')}
                      placeholder='22AAAAA0000A1Z5'
                      invalid={errors.gst_number && true}
                    />
                    {errors && errors.gst_number && (
                      <FormFeedback style={{ color: 'red', fontSize: '12px' }}>
                        {errors?.gst_number?.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Checkbox
                sx={{ ml: [-1] }}
                value={isAccepted}
                size='small'
                onChange={() => handleTermsNpolicy()}
              />
              {'  '}
              <span>
                I have read and agree to the{" "}
                <a
                  href='https://qwikskills.com/refund-policy'
                  target='_blank'
                  rel='noreferrer'
                  style={{ textDecoration: 'none', color: '#8341ea' }}
                >
                  Refund Policy
                </a>
                 ,{" "}
                <a
                  href='https://qwikskills.com/terms-and-conditions'
                  target='_blank'
                  rel='noreferrer'
                  style={{ textDecoration: 'none', color: '#8341ea' }}
                >
                  Terms and Conditions
                </a>
                .
              </span>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: '#8341ea',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#8341ea',
                  },
                }}
                type='submit'
                size='small'
                variant='contained'
                color='primary'
                disabled={!isAccepted}
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </Box>
  );
};

export default CheckoutDetails;
