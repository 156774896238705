import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../globalPages/style.css";

const ThankYou = () => {
  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 4, md: 12 }}
        sx={{
          display: "flex",
        }}
      >
        <Grid item xs={2} sm={4} md={12}>
          <Box
            className="thankYouBG"
            sx={{
              // color: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Image src={logo} alt="logo" /> */}
            <Typography
              sx={{
                fontSize: "96px",
                fontWeight: 600,
              }}
            >
              Thank You!{" "}
            </Typography>
            <Typography variant="h4" sx={{ my: 5, opacity: ".7" }}>
              Payment Successfully Done
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              Your transaction has been successfully processed and receipt sent
              to your registered email address. To access your dashboard, click
              on the button below.
            </Box>
            <a href="https://dashboard.qwikskills.com/">
              <button
                // onClick={handleDownloadBrochure}
                style={{
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                className="landingPrimaryBTN"
                variant="contained"
              >
                Explore Dashboard
              </button>
            </a>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ThankYou;
