import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./style.css";

const PackageOverview = ({ programOverview, country }) => {
  
  const benefits=programOverview?.benefits?JSON.parse(programOverview?.benefits):'';


  return (
    <Box>
      <Container>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 22, md: 36 },
              fontWeight: 700,
              mb: 3,
              textAlign: "center",
            }}
            variant="h2"
          >
            Package<span className={"hightLightText"}> Overview</span>{" "}
          </Typography>
        </Box>
        <Grid
          container
          xs={12}
          gap={2}
          mt={8}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
  flexDirection:"row",
            fontSize: "1.125rem",
          }}
        >
          <Box sx={{width:"45%"}}>
          <Grid
            item
            // xs={6}
            sx={{
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: ".5rem 1rem",
              borderRadius: "5px",
              textAlign:"left",
              width:'fit-content',
              marginLeft:"4rem"
            }}
          >
            {country?.country_code === "IN" ? (<><span style={{ fontWeight: "600" }}>Price: </span>₹{programOverview?.price}</>) :
              (<><span style={{ fontWeight: "600" }}>Price: </span>${programOverview?.price}</>)}
          </Grid>
          </Box>
          <Box sx={{width:"45%"}}>
          <Grid
            item
            // xs={6}
            
            sx={{
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: ".5rem 1rem",
              borderRadius: "5px",
              width:'fit-content'
            }}
          >
            <span style={{ fontWeight: "600" }}>Validity: </span>{programOverview?.validity}
          </Grid>
          </Box>
        </Grid>
        <Grid container xs={12} px={6} mt={7} >
          {benefits && benefits?.map((points) => (
            <Grid
              key={points.id}
              item
              xs={6}
              sx={{
                fontSize: "18px",
                lineHeight: "2.5rem",
                textAlign:"left"
              }}
            >
              <div>{points?.info || ""}</div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default PackageOverview;
