import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import ProgramOverview from "./paymentOverview";
import AdmissionFeeAndFinancing from "./admissionFee";
import axios from "axios";
import { State } from "country-state-city";
import Loader from '../../components/Loader/loader' ;
import { useParams } from "react-router-dom";
import NoData from '../../images/broken_training.jpg'

const PaymentPage = () => {
  const { uuid } = useParams();

  const [country, setCountry] = useState({
    country_name: "",
    country_code: "",
  });
  const [ipAddress, setIpAddress] = useState(""); //eslint-disable-line
  const [state, setState] = useState([]);
  const [programOverview, setProgramOverview] = useState(null);
  const [courseDetail, setCourseDetail] = useState({});
  const [pageUUID, setPageUUID] = useState(null);
  const [pageSlug,setPageSlug]=useState({
    slug:"",
    category:"",
  });
  const [isDataAvailable, setIsDataAvailable] = useState(true);

  const fetchCourseDetails = (slug) => {
    pageUUID && axios
      .get(
        `${process.env.REACT_APP_API_URL}/trainingpage/program-overview/get/?pageUUID=${pageUUID}`
      )
      .then((res) => {
        setProgramOverview(res?.data);
        setPageSlug({
          slug:res?.data?.admissionFee?.AdmissionFeeTrainingPages?.slug,
          category:res?.data?.admissionFee?.AdmissionFeeTrainingPages?.category,
        });
        setCourseDetail({
          course_id: res?.data?.admissionFee?.AdmissionFeeCourse?.id,
          course_price: res?.data?.admissionFee?.AdmissionFeeCourse?.price,
        });
        setIsDataAvailable(true);
      })
      .catch((err) => {
        console.error(err);
        setIsDataAvailable(false);
      });
  };

  useEffect(() => {

    if (!uuid) {
      return;
    }
    setPageUUID(uuid);

  }, []); //eslint-disable-line



  useEffect(() => {
    const fetchIpCountry = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;
        setIpAddress(ipAddress);
        localStorage.setItem("ipAddress", ipAddress);

        // Fetch the country information
        const countryResponse = await axios.get(
          `https://ipapi.co/${ipAddress}/json/`
        );
        
        const countryName = countryResponse?.data?.country_name;
        const countryCode = countryResponse?.data?.country_code;
        setCountry({
          country_name: countryName,
          country_code: countryCode,
        });
        localStorage.setItem("country",countryCode);
      } catch (error) {
        console.error("Error fetching IP and country:", error);
      }
    };
    fetchIpCountry();
    fetchCourseDetails(pageUUID);
  }, [pageUUID]); // eslint-disable-line



  useEffect(() => {
    if (country?.country_code) {
      const state = State?.getStatesOfCountry(country.country_code);
      setState(state);
    }
  }, [country]);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // pt: 3,
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "1700px" }}>
          {country?.country_code && state ? (
            isDataAvailable === true ? <Grid container xs={12} pt={3}>
              <Grid item xs={12} lg={6} sm={12}>
                <ProgramOverview
                  programOverview={programOverview}
                  country={country}
                />
              </Grid>
              <Grid item xs={12} lg={6} sm={12}>
                <AdmissionFeeAndFinancing
                  state={state}
                  country={country}
                  course={courseDetail}
                  pageSlug={pageSlug}
                />
              </Grid>
            </Grid> : <Grid container xs={12} sx={{display:'flex',justifyContent:"center",alignItems:"center"}}>
             <div><img style={{width:"100vw", height:"100vh"}} src={NoData} alt="No training found" />
             <div className="notFound">
             <a href="https://qwikskills.com/"><button className="backbutton">Back to Home</button></a> 
             </div>
             </div>
            </Grid>
          ) : (
            <Box sx={{ height: "90vh" }}><Loader /></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentPage;
