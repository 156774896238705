import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./style.css";
import Ellipse from "../../images/Ellipse.png";

const ProgramOverview = ({ programOverview, country }) => {

  const programOverviewPoint = [];
  programOverview?.programoverview?.list?.split('\n')?.map((point, index) => (
    programOverviewPoint.push({
      id: index,
      point: point
    })
  ))


  return (
    <Box>
      <Container>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 22, md: 36 },
              fontWeight: 700,
              mb: 3,
              textAlign: "center",
            }}
            variant="h2"
          >
            Program<span className={"hightLightText"}> Overview</span>{" "}
          </Typography>
        </Box>
        <Grid
          container
          xs={12}
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            px: 5,
            fontSize: "1.125rem",
          }}
        >
          <Grid
            item
            sx={{
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: ".5rem 1rem",
              borderRadius: "5px",
            }}
          >
            {country?.country_code === "IN" ? (<><span style={{ fontWeight: "600" }}>Price: </span>₹{programOverview?.admissionFee?.AdmissionFeeCourse?.price?.INR?.discountedPrice}</>) :
              (<><span style={{ fontWeight: "600" }}>Price: </span>${programOverview?.admissionFee?.AdmissionFeeCourse?.price?.USD?.discountedPrice}</>)}
          </Grid>
          <Grid
            item
            sx={{
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: ".5rem 1rem",
              borderRadius: "5px",
            }}
          >
            <span style={{ fontWeight: "600" }}>Validity: </span>{programOverview?.admissionFee?.validity}
          </Grid>
          <Grid
            item
            sx={{
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: ".5rem 1rem",
              borderRadius: "5px",
            }}
          >
            <span style={{ fontWeight: "600" }}>Duration: </span>{programOverview?.admissionFee?.training_duration}
          </Grid>
        </Grid>
        <Grid container xs={12} px={6} mt={3}>
          {programOverviewPoint?.map((points) => (
            <Grid
              key={points.id}
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                fontSize: "16px",
                lineHeight: "2rem",
                // my: 1,
              }}
            >
              <div style={{ marginRight: "10px", marginTop: "15px" }}>
                <img src={Ellipse} alt="qwikskills" />
              </div>
              <div className="dangerouslySetInnerHTML" dangerouslySetInnerHTML={{ __html: points?.point }}></div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProgramOverview;
