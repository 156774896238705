import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "../globalPages/style.css";

export const NotFound = () => {


  useEffect(() => {
    setInterval(() => {
      window.location.assign("https://qwikskills.com/");
    }, 3000);
  }, []); //eslint-disable-line


  return (
    <Box className="pagenotfound">
      <Box className="notfound">
        <Typography variant="h3" sx={{textAlign: "center"}}>404</Typography>
        <Box sx={{ textAlign: "center", m: 5, fontSize: "2rem" }}>
          Page Not Found
        </Box>
      </Box>
    </Box>
  );
};
