import { Box} from '@mui/material'
import React from 'react'
import './navbar.css'
import logo from '../../images/qwikskillslogo.svg';
const Navbar = () => {
  return (
    <Box>
        <nav className='appbar'  >
          <Box sx={{width:"80%", my:2 , mx:1}}>
          <a href='https://qwikskills.com/'>
          <img src={logo} alt='logo' />
            </a>
            </Box>  
        </nav>
    </Box>
  )
}

export default Navbar