import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormGroup,
    Grid,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormFeedback, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import "../style.css";
import RazorpayImage from '../../../images/razorpay.svg'
import Stripe from '../../../images/stripe.png';
import './CardSectionStyles.css';
import Qwikskillslogo from '../../../images/qwikskillslogo.svg'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getDecryptedData } from "../../../Utility/utils";
import Alerts from "../../../components/globalPages/Alerts";



const RazorPayment = ({ country, course,pageSlug }) => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [user, setUser] = useState('');
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const RazorpayPayment = async (response) => {

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            return;
        }
        var data = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            // logo, displayed in the payment processing popup
            image: { Qwikskillslogo },
            name: "Qwikskills",
            amount: response?.amount, // in currency subunits. Here 1000 = 1000 paise, which equals to ₹10
            currency: response?.currency,// Default is INR. We support more than 90 currencies.
            email: user?.email,
            contact: user?.phone,
            notes: {
                address: response?.notes?.stateName
            },
            order_id: response?.razorpayOrderId,// this will comes from from razorpay.makeOrder response
            prefill: {
                ...user
            },
            display: {
                offers: false,
            },

            modal: {
                ondismiss: () => {
                    navigate('/failed');
                },
            },
            handler: async function (response) {
                const requestData = {
                    razorpayPaymentId: response?.razorpay_payment_id,
                    razorpayOrderId: response?.razorpay_order_id,
                    razorpaySignature: response?.razorpay_signature
                }
                axios.post(`${process.env.REACT_APP_API_URL}/payments/verify-rzp`, requestData).then((res) => {
                    setIsSubmitting(false);
                    navigate('/thankyou');
                }).catch((err) => {
                    navigate('/failed');
                     setIsSubmitting(false);
                })
            },
        };
        var razorpay = new window.Razorpay(data);
        razorpay.open();
    }

    useEffect(() => {
        const userDetails = localStorage.getItem("userData");
        const getDecrypted= getDecryptedData(JSON.parse(userDetails));
        setUser(getDecrypted);
    }, [country])

    const onSubmitHandler = async (datas) => {

        setIsSubmitting(true);
        const requestBody = {
            "email": user?.email,
            // "courseId": 1,
            "courseId": course?.course_id,
            "paymentGateway": "RAZORPAY",
            // "amount": "1",
            "amount": course?.course_price?.INR?.discountedPrice,
            "firstName": user?.firstName,
            "lastName": user?.lastName,
            "phoneNumber": user?.phone,
            "stateName": user?.state,
            "gstNumber": user?.gst_number || "",
            "companyName": user?.company_name || "",
        }
        course?.course_id && course?.course_price?.INR?.discountedPrice &&
         axios.post(`${process.env.REACT_APP_API_URL}/payments/live-course`, requestBody).then(async (res) => {
            RazorpayPayment(res?.data?.data)
        }).catch((err) => {
            setIsSubmitting(false);
            Alerts.error(err?.response?.data?.message);
            setInterval(() => {
                window.location.assign(`https://qwikskills.com/${pageSlug?.category}/${pageSlug?.slug}`);
              }, 3000);
        })
        reset();
    };

    return (
        <Box>
            <Box sx={{ textAlign: 'center', mb: 3 }}>
                {country && country?.country_code === 'IN' ? <img src={RazorpayImage} alt="" /> : <img src={Stripe} alt="" height={'50px'} />}
            </Box>
            {/* <CardElement /> */}
            <form onSubmit={handleSubmit(onSubmitHandler)} className="payment-form">
                <Container>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            // px: 3,
                            borderRadius: "10px",
                            // py: 3,
                            gap: 3,
                            padding: "60px"
                        }}
                    >
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between' }}>
                            <Grid xs={12} lg={7}>
                                <FormGroup>
                                    <Label required style={{ marginBottom: ".5rem", fontWeight: "600", opacity: ".6" }}>
                                        Email<span style={{ color: "red" }} >*</span>{" "}
                                    </Label>
                                    <TextField size={"small"} {...register("email")} value={user && user?.email} disabled />
                                    {errors.email && (
                                        <FormFeedback style={{ color: "red", fontSize: "12px" }}>
                                            {errors?.email?.message}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <FormGroup>
                                    <Label required style={{ marginBottom: ".5rem", fontWeight: "600", opacity: ".6" }}>
                                        Pay Amount<span style={{ color: "red" }}>*</span>{" "}
                                    </Label>
                                    <TextField size={"small"} {...register("amount")} value={course?.course_price &&course?.course_price?.INR?.discountedPrice } disabled />
                                    {errors.amount && (
                                        <FormFeedback style={{ color: "red", fontSize: "12px" }}>
                                            {errors?.amount?.message}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    backgroundColor: "#8341ea",
                                    fontSize: "16px",
                                    "&:hover": {
                                        backgroundColor: "#8341ea",
                                    },
                                }}
                                type="submit"
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                            >
                                Complete payment {isSubmitting &&  <CircularProgress color="inherit" sx={{marginLeft:"1rem"}} size={"1.5rem"}  disableShrink /> }
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </Box>
    );
};



export default RazorPayment;
