import React, { useEffect } from "react";
function Loader() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        zIndex: 999000,
        left: 0,
        top: 0,
        background: "rgba(0,0,0,0.4)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: 999000,
        }}
      >
        {/* <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="paint0_linear_48_326" d="M44.3913 26.1912L36.6397 34.0491L32.5993 11.9537L10.8027 7.85792L18.5533 0L40.3509 4.09574L44.3913 26.1912Z" fill="url(#paint0_linear_48_326)" />
          <path id="paint1_linear_48_326" d="M33.5885 37.142L25.8369 45L21.7966 22.9034L0 18.8076L7.75162 10.9508L29.5482 15.0465L33.5885 37.142Z" fill="url(#paint1_linear_48_326)" />
          <defs>
            <linearGradient id="paint0_linear_48_326" x1="26.6113" y1="22.4969" x2="25.9156" y2="1.62429" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B4BE9" />
              <stop offset="1" stop-color="#5B43EA" />
            </linearGradient>
            <linearGradient id="paint1_linear_48_326" x1="15.8086" y1="33.4477" x2="15.1128" y2="12.5751" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B4BE9" />
              <stop offset="1" stop-color="#5B43EA" />
            </linearGradient>
          </defs>
        </svg> */}
        <img src="https://qwikappstorage.blob.core.windows.net/uploads/qwikStaticAssets/commonAssets/loader_transparent.gif" alt="qwikskills" />
      </div>
    </div>
  );
}

export default Loader;
